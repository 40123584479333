import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

import '../../generic-page.scss';

const REVGuidesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="reverse">
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_guides.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Guides</h1>
          <h2>Our guides for Reverse: 1999 gacha game.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Newbie zone" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Introduction to the game"
            link="/re1999/guides/introduction-to-the-game"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_intro.png"
                alt="Introduction to the game"
              />
            }
          />
          <CategoryCard
            title="Reroll guide"
            link="/re1999/guides/reroll"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_reroll.webp"
                alt="Reroll guide"
              />
            }
          />
          <CategoryCard
            title="Beginner guide"
            link="/re1999/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_beginner.png"
                alt="Beginner guide"
              />
            }
          />
          <CategoryCard
            title="Team Building"
            link="/re1999/guides/team-building"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_teams.jpg"
                alt="Team Building"
              />
            }
          />
          <CategoryCard
            title="Euphoria & Reveries"
            link="https://blog.prydwen.gg/2025/02/23/reverse-1999-euphoria-reveries-in-the-rain-explained/"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_cryptid.webp"
                alt="Map"
              />
            }
            isNew
            outsideLink
          />
          <CategoryCard
            title="Reveries in the Rain"
            link="/re1999/guides/reveries-in-the-rain"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_rain.webp"
                alt="Map"
              />
            }
            isNew
          />
        </Row>
        <SectionHeader title="Generic guides" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Banner history (CN)"
            link="/re1999/guides/banner-history-cn"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_banners_cn.png"
                alt="Banner history (CN)"
              />
            }
          />
          <CategoryCard
            title="Insight Mats. Cheat Sheet"
            link="/re1999/guides/insight-cheat-sheet"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_insight.png"
                alt="Insight Cheat Sheet"
              />
            }
          />
          <CategoryCard
            title="Psychubes Cheat Sheet"
            link="/re1999/guides/psychube-cheat-sheet"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_psycheat.png"
                alt="Psychube Cheat sheet"
              />
            }
          />
          <CategoryCard
            title="Critter Rehab Center Startup Guide"
            link="/re1999/guides/critter-rehab-center"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_critter.webp"
                alt="Critter Rehab Center Startup Guide"
              />
            }
          />
        </Row>
        <SectionHeader title="Mane's Bulletin (Raid Boss) Guides" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mane's Bulletin (Raid Boss)"
            link="/re1999/guides/mane-bulletin"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_raid.jpg"
                alt="Team Building"
              />
            }
          />
          <CategoryCard
            title="Darkness of the Abyss"
            link="/re1999/guides/darkness-of-the-abyss"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_abyss.webp"
                alt="Team Building"
              />
            }
          />
          <CategoryCard
            title="Marsh Creation"
            link="/re1999/guides/marsh-creation"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_marsh.webp"
                alt="Team Building"
              />
            }
          />
          <CategoryCard
            title="Gold in the Cave"
            link="/re1999/guides/gold-in-the-cave"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_gold.webp"
                alt="Team Building"
              />
            }
          />
          <CategoryCard
            title="Hypothesis of Exhibition"
            link="/re1999/guides/hypothesis-of-exhibition"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_hypothesis.webp"
                alt="Team Building"
              />
            }
          />
          <CategoryCard
            title="Projection of Nightmares"
            link="/re1999/guides/projection-of-nightmares"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_projection.webp"
                alt="Team Building"
              />
            }
          />
          <CategoryCard
            title="Isle Cryptid"
            link="/re1999/guides/isle-cryptid"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_cryptid.webp"
                alt="Team Building"
              />
            }
          />
          <CategoryCard
            title="Echoes of Opera"
            link="/re1999/guides/echoes-of-opera"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_opera.webp"
                alt="Team Building"
              />
            }
          />
          <CategoryCard
            title="Bellows of the Earth"
            link="/re1999/guides/bellows-of-the-earth"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_bellows.webp"
                alt="Team Building"
              />
            }
          />
          <CategoryCard
            title="Fog of War"
            link="/re1999/guides/fog-of-war"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_fog.webp"
                alt="Team Building"
              />
            }
            isNew
          />
        </Row>
        <SectionHeader title="Should you pull guides" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Should you pull Barcarola?"
            link="/re1999/guides/should-you-pull-barcarola"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_barcarola.webp"
                alt="Should you pull Barcarola?"
              />
            }
            isNew
          />
          <CategoryCard
            title="Should you pull Fatutu?"
            link="/re1999/guides/should-you-pull-fatutu"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_fatutu.webp"
                alt="Should you pull Fatutu?"
              />
            }
            isNew
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default REVGuidesPage;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Reverse: 1999 | Prydwen Institute"
    description="Guides for Reverse: 1999 gacha game."
    game="reverse"
  />
);
